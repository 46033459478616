import React, { useCallback } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import FieldText from '@react-form-fields/material-ui/components/Text';

type InputMoneyProps = NumberFormatProps;

const InputMoney = React.forwardRef<HTMLInputElement, InputMoneyProps>(
  (props: InputMoneyProps, ref) => {
    const defaultInput = useCallback(
      ({ onChange, value, ...rest }) => {
        return (<FieldText
          {...rest}
          inputProps={{ onChange, value }}
          onChange={() => undefined}
          ref={ref}
        />)
      },
      [ref]
    );

    return (
      <NumberFormat
        tel="tel"
        customInput={props.customInput || defaultInput}
        thousandSeparator="."
        decimalSeparator=","
        fixedDecimalScale
        decimalScale={2}
        prefix="R$"
        {...props}
      />
    );
  }
);

export default InputMoney;
