import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import api from '../../services/api';

import { Container, Title, TableWrapper, SendButton } from './styles';
import Header from '../../components/Header';

interface IContacts {
  _id: string;
  email: string;
  cnpj: string;
  name: string;
}

const Emails = () => {
  const [emails, setEmails] = useState<IContacts[]>([]);
  const user = localStorage.getItem('user');
  const history = useHistory();

  const getEmails = useCallback(async () => {
    const result  = await api.get('/email');
    setEmails(result.data);
  }, []);

  useEffect(() => {
    if (!user) {
      history.push('/admin');
    } else {
      getEmails();
    }
  }, []);

  return (
    <Container>
      <Header />
      <Title>
        Olá, bem vindo admin.
      </Title>
      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>CNPJ/CPF</TableCell>
              <TableCell>NOME/RAZÃO SOCIAL</TableCell>
              <TableCell>E-MAIL</TableCell>
              <TableCell>AÇÃO</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { emails.map((item) => (
              <TableRow key={item._id}>
                <TableCell>
                  {item.cnpj}
                </TableCell>
                <TableCell>
                  {item.name}
                </TableCell>
                <TableCell>
                  {item.email}
                </TableCell>
                <TableCell>
                  <SendButton onClick={() => history.push(`/list/${item._id}`)}>
                    VISUALIZAR
                  </SendButton>
                </TableCell>
              </TableRow>
            )) }
          </TableBody>
        </Table>
      </TableWrapper>
    </Container>  
  )
}

export default Emails;