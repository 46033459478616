import React, { useRef, useState } from 'react';
import { Box, Button, Tooltip } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import api from '../../services/api';
import { IFile } from '../../interfaces';
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';
import { fileToBase64 } from '../../helper/fileToBase64';

type Props = {
  id: string;
  type: string;
  tooltip?: string;
  currentFile?: IFile[];
  placeholder: string;
  handleSubmit: (files: IFile[]) => void;
}

const Alert = (props: any) => {
  return <MuiAlert elevation={10} variant="filled" {...props} />;
}

const InputFiles = (props: Props) => {
  const { id, type, currentFile, tooltip = '', placeholder, handleSubmit } = props;
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState(currentFile || []);
  const ref = useRef<HTMLInputElement>(null);

  const handleDelete = async (file: IFile) => {
    try {
      setLoading(true);
      const result = await api.delete(`/loan/file?id=${id}&key=${file.key}&file=${type}`);
  
      setLoading(false);
      setFiles(result.data[type]);
      handleSubmit(result.data[type]);
    } catch (err) {
      setLoading(false);
      setError(true);
      console.log(err);
    }
  }

  const handleChange = async (event: any) => {
    try {
      setLoading(true);
      const file = await fileToBase64(event.target.files[0]);
      const name = event.target.files[0].name;

      const result = await api.put(`/loan/upload?id=${id}`, { file, name, type });
  
      setLoading(false);
      setFiles(result.data[type]);
      handleSubmit(result.data[type]);
    } catch (err) {
      setLoading(false);
      setError(true);
      console.log(err);
    }
  }

  return (
    <>
      <Snackbar open={error} autoHideDuration={10000} onClose={() => setError(false)}>
        <Alert onClose={() => setError(false)} severity="error">
          Ocorreu um erro interno.
        </Alert>
      </Snackbar>
      <Tooltip title={tooltip}>
        <Button
          variant="contained"
          color="primary"
          component="label"
          style={{ width: '100%', marginBottom: '10px' }}
          onKeyDown={(e: any) => e.keyCode === 32 && ref.current?.click()}
        >
          { loading ? 'CARREGANDO...' : placeholder }
          <input
            ref={ref}
            placeholder={placeholder}
            type="file"
            onChange={handleChange}
            accept="application/pdf"
            multiple
            hidden
          />
        </Button>
      </Tooltip>
      <Box display="flex" maxHeight="70px" width="100%" overflow="auto">
        {files.map(file => (
          <Box display="flex" marginLeft="5px" justifyContent="space-between">
            <a style={{ marginRight: '10px' }} href={file.path} target="_blank" rel="noopener noreferrer">
              {placeholder.split(' ')[0]}
            </a>
            <HighlightOffIcon onClick={() => handleDelete(file)} style={{ color: '#cf4647', cursor: 'pointer' }} />
          </Box>
        ))}
      </Box>
    </>
  );
}

export default InputFiles;
