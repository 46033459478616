import { Route, Switch } from 'react-router';
import Admin from './Admin';
import Emails from './Emails';
import Home from './Home';
import LoanDetail from './LoanDetail';
import LoanList from './LoanList';

const Pages = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/admin" component={Admin} />
      <Route path="/details/:loanId" component={LoanDetail} />
      <Route path="/list/:email" component={LoanList} />
      <Route path="/emails" component={Emails} />
    </Switch>
  );
}

export default Pages;
