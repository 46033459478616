import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Controller, UseFormMethods } from 'react-hook-form';
import { Typography } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import { LoanFormFields } from '..';

type Props = {
  methods: UseFormMethods<LoanFormFields>;
}

export default function SecondStep(props: Props) {
  const { methods } = props;
  const { register, formState: { errors }, control } = methods;

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid container alignItems="center" md={3} xs={12}>
        <Grid item xs={11}>
          <Controller
            control={control}
            name="initialDate"
            render={({ onChange, value }) => (
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Data de inicio das atividades"
                value={value}
                onChange={(newDate) => onChange(newDate)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={1}>
          <Tooltip title="Data de abertura da empresa">
            <HelpOutlineIcon style={{ color: '#6d6d6d', cursor: 'pointer' }} />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-end" md={3} xs={12}>
        <Grid item xs={11}>
          <TextField
            name="primaryActivity"
            label="Atividade principal"
            inputRef={register()}
            error={!!errors.primaryActivity}
            type="text"
            fullWidth
          />
        </Grid>
        <Grid item xs={1}>
          <Tooltip title="Informe atividade principal. Ex: Comercio e serviço">
            <HelpOutlineIcon style={{ color: '#6d6d6d', cursor: 'pointer' }} />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-end" md={3} xs={12}>
        <Grid item xs={11}>
          <TextField
            name="employeersQtd"
            label="Número de funcionários"
            error={!!errors.employeersQtd}
            inputRef={register()}
            type="number"
            fullWidth
          />
        </Grid>
        <Grid item xs={1}>
          <Tooltip title="Informe a Qtd de funcionários da empresa">
            <HelpOutlineIcon style={{ color: '#6d6d6d', cursor: 'pointer' }} />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-end" md={3} xs={12}>
        <Grid item xs={11}>
          <TextField
            name="website"
            label="Site"
            inputRef={register()}
            type="text"
            fullWidth
          />
        </Grid>
        <Grid item xs={1}>
          <Tooltip title="Informe o site. Ex: www.seusite.com.br">
            <HelpOutlineIcon style={{ color: '#6d6d6d', cursor: 'pointer' }} />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container justify="center" style={{ margin: '10px 0' }} xs={12}>
        <Typography style={{ fontWeight: 'bold' }}>REFERÊNCIAS BANCÁRIAS</Typography>
      </Grid>
      <Grid item md={4} xs={12}>
       <TextField
          name="bankInfo.bank"
          label="Banco"
          error={!!errors.bankInfo?.bank}
          inputRef={register()}
          type="text"
          fullWidth
        />
      </Grid>
      <Grid item md={4} xs={12}>
       <TextField
          name="bankInfo.agency"
          label="Agência"
          error={!!errors.bankInfo?.agency}
          inputRef={register()}
          type="text"
          fullWidth
        />
      </Grid>
      <Grid item md={4} xs={12}>
       <TextField
          name="bankInfo.account"
          label="Conta"
          error={!!errors.bankInfo?.account}
          inputRef={register()}
          type="text"
          fullWidth
        />
      </Grid>
    </Grid>
  )
}
