import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import { IPersonal } from '../../interfaces';

import { Button } from './styles';

type Props = {
  items: IPersonal[];
  handleDelete: (personal: IPersonal) => void;
}

const PartnerList = (props: Props) => {
  const { items, handleDelete } = props;

  return (
    <div style={{ maxHeight: 200, overflow: 'auto', width: '100%' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>CPF</TableCell>
            <TableCell>Nome</TableCell>
            <TableCell>Ação</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { items.map((personal, i) => (
            <TableRow style={{ background: '#d3f3c8' }} key={i}>
              <TableCell>{personal.document}</TableCell>
              <TableCell>{personal.name}</TableCell>
              <TableCell>
                <Button onClick={() => handleDelete(personal)}>Excluir</Button>
              </TableCell>
            </TableRow>
          )) }
        </TableBody>
      </Table>
    </div>
  );
}

export default PartnerList;
