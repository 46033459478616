export const fileToBase64 = (file: any) => {
  return new Promise(resolve => {
    const reader = new FileReader();
    // Read file content on file loaded event
    reader.onload = function(event) {
      if (event) {
        resolve(event.target?.result);
      }
    };
    
    // Convert data to base64 
    reader.readAsDataURL(file);
  });
};
