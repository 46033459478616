import React, { useState } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

interface IStep {
  label: string;
  component: JSX.Element;
}

type Props = {
  steps: IStep[];
  onCancel: (activeStep: number) => void;
  onNext: (activeStep: number) => void;
  triggerValidation: () => Promise<boolean>;
};

export default function FormStepper(props: Props) {
  const { steps, onCancel, onNext, triggerValidation } = props;
  const [activeStep, setActiveStep] = useState<number>(0);

  const handleNextStep = async () => {
    const validation = await triggerValidation();
    if (validation) {
      activeStep < steps.length - 1 && setActiveStep(prevActiveStep => prevActiveStep + 1);
      return onNext(activeStep);
    }
  };
  const handleBackStep = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  return (
    <>
      {activeStep >= 0 && (
        <>
          <Stepper style={{ overflow: 'auto' }} activeStep={activeStep}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <Grid container alignItems="center" spacing={2}>
            {steps[activeStep].component}
          </Grid>
        </>
      )}

      <Box display="flex" justifyContent="flex-end" marginTop="20px" marginRight="20px">
        <Button onClick={() => onCancel(activeStep)}>
          Salvar
        </Button>
        { activeStep >= 1 && (
          <Button onClick={handleBackStep}>
            Voltar
          </Button>
        ) }
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextStep}
          style={{ marginLeft: 8 }}
        >
          {activeStep === steps.length - 1 ? 'Finalizar' : 'Próximo'}
        </Button>
      </Box>
    </>
  );
}
