import React, { useState } from 'react';
import { Button, Grid, TextField, Tooltip } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import InputMask from 'react-input-mask';
import { IThird } from '../../interfaces';
import InputMoney from '../Form/InputMoney';
import { NumberFormatValues } from 'react-number-format';

interface IProps {
  onAdd: (data: IThird) => void;
  type: string;
  tooltip: string;
}

const CreateThird = (props: IProps) => {
  const { onAdd, tooltip, type } = props;
  const [mask, setMask] = useState('999.999.999-999');
  const [name, setName] = useState('');
  const [document, setDocument] = useState('');
  const [value, setValue] = useState('');

  const onDocChange = (e: any) => {
    if (e.target.value[mask.length - 1] !== '_') {
      setMask('99.999.999/9999-99')
    }
    if (e.target.value[0] === '_') {
      setMask('999.999.999-999')
    }
    return setDocument(e.target.value);
  }

  const handleAdd = () => {
    onAdd({ document, name, value });
    setName('');
    setDocument('');
    setValue('');
    setMask('999.999.999-999');
  }

  return (
    <>
      <Grid container alignItems="flex-end" md={3} xs={12}>
        <Grid item xs={11}>
          <InputMask
            mask={mask}
            value={document}
            maskPlaceholder={null}
            onChange={e => onDocChange(e)}
          >
            { () =>
              <TextField
                label="CNPJ/CPF"
                type="text"
                fullWidth
              />
            }
          </InputMask>
        </Grid>
        <Grid item xs={1}>
          <Tooltip title="Informe o CNPJ ou CPF">
            <HelpOutlineIcon style={{ color: '#6d6d6d', cursor: 'pointer' }} />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-end" md={3} xs={12}>
       <Grid item xs={11}>
          <TextField
            label="Nome/Razão Social"
            value={name}
            onChange={e => setName(e.target.value)}
            type="text"
            fullWidth
          />
       </Grid>
       <Grid item xs={1}>
          <Tooltip title="Informe seu nome ou razão social">
            <HelpOutlineIcon style={{ color: '#6d6d6d', cursor: 'pointer' }} />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-end" md={3} xs={12}>
        <Grid item xs={11}>
          <InputMoney
            onChange={(e: any) => console.log(e)}
            onValueChange={({ floatValue }: NumberFormatValues) => {
              setValue(floatValue?.toString() || '');
            }}
            label="Média de compra mês"
          />
        </Grid>
        <Grid item xs={1}>
          <Tooltip title={tooltip}>
            <HelpOutlineIcon style={{ color: '#6d6d6d', cursor: 'pointer' }} />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item md={3} xs={12}>
       <Button
        variant="contained"
        color="primary"
        onClick={() => handleAdd()}>
          Adicionar {type}
        </Button>
      </Grid>
    </>
  );
}

export default CreateThird;
