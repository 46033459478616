import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h2`
  color: #333;
`;

export const TableWrapper = styled.div`
  width: 70%;
  height: 500px;
  overflow: auto;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const SendButton = styled.button`
  background-color: #000;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 10px;
  cursor: pointer;
`;
