export enum MaritalStatus {
  CASADO_COMUNHAO_PARCIAL = 'CASADO(A) COMUNHÃO PARCIAL',
  CASADO_COMUNHAO_TOTAL = 'CASADO(A) COMUNHÃO TOTAL',
  CASADO_SEPARAÇAO_TOTAL = 'CASADO(A) SEPARAÇÃO TOTAL',
  DIVORCIADO = 'DIVORCIADO',
  SOLTEIRO = 'SOLTEIRO'
}

export enum ValidMaritalStatus {
  CASADO_COMUNHAO_PARCIAL = 'CASADO(A) COMUNHÃO PARCIAL',
  CASADO_COMUNHAO_TOTAL = 'CASADO(A) COMUNHÃO TOTAL',
  CASADO_SEPARAÇAO_TOTAL = 'CASADO(A) SEPARAÇÃO TOTAL'
}