import React, { useState } from 'react';
import { useHistory } from 'react-router';
import Header from '../../components/Header';
import api from '../../services/api';

import { Container, Form, Title, Input, Button, Message } from './styles';

const Admin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);

  const history = useHistory();

  const handleSubmit = async () => {
    try {
      const result = await api.post('/admin', { email, password });
      if (!result.data) {
        return setError(true);
      } else {
        localStorage.setItem('user', JSON.stringify(result.data));
        history.push('/emails');
      }
    } catch (err) {
      console.log(err);
    }
  }
  
  return (
    <Container>
      <Header />
      <Form>
        <Title>Login</Title>
        <Input placeholder="E-mail" type="e-mail" value={email} onChange={(e: any) => setEmail(e.target.value)} />
        <Input
          placeholder="Senha" type="password" value={password} onChange={(e: any) => setPassword(e.target.value)} />
        <Button onClick={() => handleSubmit()}>ACESSAR</Button>

        { error && <Message>E-mail ou senha incorretos</Message> }
      </Form>
    </Container>
  )
}

export default Admin;