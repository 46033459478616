import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useHistory, useParams } from 'react-router';
import { LoanFormFields } from '../../components/CreateLoan';
import api from '../../services/api';

type LoanParams = {
  loanId: string;
}

const LoanDetail = () => {
  const [loan, setLoan] = useState<LoanFormFields | null>(null);
  const { loanId } = useParams<LoanParams>();

  const history = useHistory();

  const getLoan = useCallback(async () => {
    try {
      const result = await api.get(`/loan/id/${loanId}`);
  
      setLoan(result.data);
      window.print();
    } catch (err) {
      console.log(err);
      return history.push('/');
    }
  }, []);

  useEffect(() => {
    if (!loanId) {
      history.push('/');
    }
    getLoan();
  }, []);

  return (
    <Grid container alignItems="center">
      { loan && (
        <>
          <ExitToAppIcon
            fontSize="large"
            onClick={() => history.goBack()}
            style={{
              position: 'absolute',
              top: 50,
              right: 50,
              color: '#cf4647',
              cursor: 'pointer'
            }}
          />
          <img
            src="https://loan-management.s3.sa-east-1.amazonaws.com/essential-logo-black.png"
            style={{ width: '400px', margin: 'auto' }}
          />
          <Grid container justify="center" style={{ margin: '10px 0', background: '#dcdbe9' }} md={12}>
            <Typography style={{ fontWeight: 'bold', marginTop: '10px' }}>DADOS CADASTRAIS DA EMPRESA</Typography>
          </Grid>
          <Grid container alignItems="center" justify="center" style={{ margin: '10px 0' }} md={12}>
            <Grid item style={{ marginBottom: '10px' }} md={6}>
              <label style={{ fontWeight: 'bold', margin: '0 10px' }}>CNPJ/CPF:</label>
              { loan.document }
            </Grid>
            <Grid item style={{ marginBottom: '10px' }} md={6}>
              <label style={{ fontWeight: 'bold', margin: '0 10px' }}>Nome/Razão Social:</label>
              { loan.name }
            </Grid>
            <Grid item style={{ marginBottom: '10px' }} md={6}>
              <label style={{ fontWeight: 'bold', margin: '0 10px' }}>CEP:</label>
              { loan.addressCep }
            </Grid>
            <Grid item style={{ marginBottom: '10px' }} md={6}>
              <label style={{ fontWeight: 'bold', margin: '0 10px' }}>Logradouro:</label>
              { loan.address }
            </Grid>
            <Grid item style={{ marginBottom: '10px' }} md={6}>
              <label style={{ fontWeight: 'bold', margin: '0 10px' }}>Complemento:</label>
              { loan.addressComplement }
            </Grid>
            <Grid item style={{ marginBottom: '10px' }} md={6}>
              <label style={{ fontWeight: 'bold', margin: '0 10px' }}>Cidade:</label>
              { loan.addressCity }
            </Grid>
            <Grid item style={{ marginBottom: '10px' }} md={4}>
              <label style={{ fontWeight: 'bold', margin: '0 10px' }}>Bairro:</label>
              { loan.addressDistrict }
            </Grid>
            <Grid item style={{ marginBottom: '10px' }} md={4}>
              <label style={{ fontWeight: 'bold', margin: '0 10px' }}>Telefone Fixo:</label>
              { loan.phoneFix }
            </Grid>
            <Grid item style={{ marginBottom: '10px' }} md={4}>
              <label style={{ fontWeight: 'bold', margin: '0 10px' }}>Telefone Celular:</label>
              { loan.phoneCel }
            </Grid>
          </Grid>
          <Grid container justify="center" style={{ margin: '10px 0', background: '#dcdbe9' }} md={12}>
            <Typography style={{ fontWeight: 'bold', marginTop: '10px' }}>RESPONSÁVEL LEGAL</Typography>
          </Grid>
          <Grid container alignItems="center" justify="center" style={{ margin: '10px 0' }} md={12}>
            { loan.personalInfo.map(personal => (
              <>
              <Grid item style={{ marginBottom: '10px' }} md={6}>
                <label style={{ fontWeight: 'bold', margin: '0 10px' }}>CPF:</label>
                { personal.document }
              </Grid>
              <Grid item style={{ marginBottom: '10px' }} md={6}>
                <label style={{ fontWeight: 'bold', margin: '0 10px' }}>Responsável legal:</label>
                { personal.name }
              </Grid>
              </>
            )) }
          </Grid>
          <Grid container justify="center" style={{ margin: '10px 0', background: '#dcdbe9' }} md={12}>
            <Typography style={{ fontWeight: 'bold', marginTop: '10px' }}>INFORMAÇÕES COMPLEMENTARES</Typography>
          </Grid>
          <Grid container alignItems="center" justify="center" style={{ margin: '10px 0' }} md={12}>
            <Grid item style={{ marginBottom: '10px' }} md={4}>
              <label style={{ fontWeight: 'bold', margin: '0 10px' }}>Início Atividades:</label>
              { loan.initialDate }
            </Grid>
            <Grid item style={{ marginBottom: '10px' }} md={4}>
              <label style={{ fontWeight: 'bold', margin: '0 10px' }}>Atividade Principal:</label>
              { loan.primaryActivity }
            </Grid>
            <Grid item style={{ marginBottom: '10px' }} md={4}>
              <label style={{ fontWeight: 'bold', margin: '0 10px' }}>N. Funcionários:</label>
              { loan.employeersQtd }
            </Grid>
            <Grid item style={{ marginBottom: '10px' }} md={4}>
              <label style={{ fontWeight: 'bold', margin: '0 10px' }}>Site:</label>
              { loan.website }
            </Grid>
          </Grid>
          <Grid container justify="center" style={{ margin: '10px 0', background: '#dcdbe9' }} md={12}>
            <Typography style={{ fontWeight: 'bold', marginTop: '10px' }}>REFERÊNCIAS BANCÁRIAS</Typography>
          </Grid>
          <Grid container alignItems="center" justify="center" style={{ margin: '10px 0' }} md={12}>
            <Grid item style={{ marginBottom: '10px' }} md={4}>
              <label style={{ fontWeight: 'bold', margin: '0 10px' }}>Banco:</label>
              { loan.bankInfo.bank }
            </Grid>
            <Grid item style={{ marginBottom: '10px' }} md={4}>
              <label style={{ fontWeight: 'bold', margin: '0 10px' }}>Agência:</label>
              { loan.bankInfo.agency }
            </Grid>
            <Grid item style={{ marginBottom: '10px' }} md={4}>
              <label style={{ fontWeight: 'bold', margin: '0 10px' }}>Conta:</label>
              { loan.bankInfo.account }
            </Grid>
          </Grid>
          <Grid container justify="center" style={{ margin: '10px 0', background: '#dcdbe9' }} md={12}>
            <Typography style={{ fontWeight: 'bold', marginTop: '10px' }}>PRINCIPAIS CLIENTES</Typography>
          </Grid>
          <Grid container alignItems="center" justify="center" style={{ margin: '10px 0' }} md={12}>
            { loan.clients.map(client => (
              <>
                <Grid item style={{ marginBottom: '10px' }} md={4}>
                  <label style={{ fontWeight: 'bold', margin: '0 10px' }}>CNPJ/CPF:</label>
                  { client.document }
                </Grid>
                <Grid item style={{ marginBottom: '10px' }} md={4}>
                  <label style={{ fontWeight: 'bold', margin: '0 10px' }}>Razão Social:</label>
                  { client.name }
                </Grid>
                <Grid item style={{ marginBottom: '10px' }} md={4}>
                  <label style={{ fontWeight: 'bold', margin: '0 10px' }}>Valor Compras mês:</label>
                  { client.value }
                </Grid>
              </>
            )) }
          </Grid>
          <Grid container justify="center" style={{ margin: '10px 0', background: '#dcdbe9' }} md={12}>
            <Typography style={{ fontWeight: 'bold', marginTop: '10px' }}>PRINCIPAIS FORNECEDORES</Typography>
          </Grid>
          <Grid container alignItems="center" justify="center" style={{ margin: '10px 0' }} md={12}>
            { loan.providers.map(provider => (
              <>
                <Grid item style={{ marginBottom: '10px' }} md={4}>
                  <label style={{ fontWeight: 'bold', margin: '0 10px' }}>CNPJ/CPF:</label>
                  { provider.document }
                </Grid>
                <Grid item style={{ marginBottom: '10px' }} md={4}>
                  <label style={{ fontWeight: 'bold', margin: '0 10px' }}>Razão Social:</label>
                  { provider.name }
                </Grid>
                <Grid item style={{ marginBottom: '10px' }} md={4}>
                  <label style={{ fontWeight: 'bold', margin: '0 10px' }}>Valor Compras mês:</label>
                  { provider.value }
                </Grid>
              </>
            )) }
          </Grid>
        </>
      ) }
    </Grid>
  );
}

export default LoanDetail;
