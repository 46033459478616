import React from 'react';

import { Container } from './styles';

const Header = () => {
  return (
    <Container>
      <img
        src="https://loan-management.s3.sa-east-1.amazonaws.com/essentail-logo.png"
        style={{ width: '200px' }}
      />
    </Container>
  )
}

export default Header;
