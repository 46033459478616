import React, { useState } from 'react';
import { Button, Grid, TextField, Tooltip } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import InputMask from 'react-input-mask';
import { IPersonal } from '../../interfaces';

interface IProps {
  onAdd: (data: IPersonal) => void;
}

const CreatePartner = (props: IProps) => {
  const { onAdd } = props;
  const [name, setName] = useState('');
  const [document, setDocument] = useState('');

  const handleAdd = () => {
    onAdd({ document, name });
    setName('');
    setDocument('');
  }

  return (
    <>
      <Grid container alignItems="flex-end" md={3} xs={12}>
        <Grid item xs={11}>
          <InputMask
            mask="999.999.999-99"
            value={document}
            maskPlaceholder={null}
            onChange={e => setDocument(e.target.value)}
          >
            { () =>
              <TextField
                label="CPF"
                type="text"
                fullWidth
              />
            }
          </InputMask>
        </Grid>
        <Grid item xs={1}>
          <Tooltip title="Informe CPF representante da empresa">
            <HelpOutlineIcon style={{ color: '#6d6d6d', cursor: 'pointer' }} />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-end" md={3} xs={12}>
       <Grid item xs={11}>
          <TextField
            label="Nome"
            value={name}
            onChange={e => setName(e.target.value)}
            type="text"
            fullWidth
          />
       </Grid>
       <Grid item xs={1}>
        <Tooltip title="Nome representante empresa">
          <HelpOutlineIcon style={{ color: '#6d6d6d', cursor: 'pointer' }} />
        </Tooltip>
        </Grid>
      </Grid>
      <Grid item md={3} xs={12}>
       <Button
        variant="contained"
        color="primary"
        onClick={() => handleAdd()}>
          +
        </Button>
      </Grid>
    </>
  );
}

export default CreatePartner;
