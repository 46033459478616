import * as yup from 'yup';
import { ValidMaritalStatus } from '../../interfaces/enum';

const validationForm = yup.object({
  name: yup.string().required('Obrigatório'),
  document: yup.string().required('Obrigatório'),
  addressCep: yup.string().required('Obrigatório'),
  address: yup.string().required('Obrigatório'),
  addressComplement: yup.string().required('Obrigatório'),
  addressCity: yup.string().required('Obrigatório'),
  addressDistrict: yup.string().required('Obrigatório'),
  phoneFix: yup.string().required('Obrigatório'),
  phoneCel: yup.string().required('Obrigatório'),
  primaryActivity: yup.string().required('Obrigatório'),
  employeersQtd: yup.number().required('Obrigatório'),
  personalInfo: yup.array().of(
    yup.object({
      name: yup.string().required('Obrigatório'),
      document: yup.string().required('Obrigatório'),
    })
  ).min(1).required('Obrigatório'),
  bankInfo: yup.object({
    bank: yup.string().required('Obrigatório'),
    agency: yup.string().required('Obrigatório'),
    account: yup.string().required('Obrigatório'),
  }),
  clients: yup.array().of(
    yup.object({
      name: yup.string().required('Obrigatório'),
      document: yup.string().required('Obrigatório'),
      value: yup.string().required('Obrigatório'),
    })
  ).min(1).required('Obrigatório'),
  providers: yup.array().of(
    yup.object({
      name: yup.string().required('Obrigatório'),
      document: yup.string().required('Obrigatório'),
      value: yup.string().required('Obrigatório'),
    })
  ).min(1).required('Obrigatório'),
  balanceSheet: yup.array().of(yup.object({
    key: yup.string(),
    path: yup.string()
  })),
  debtReport: yup.array().of(yup.object({
    key: yup.string(),
    path: yup.string()
  })),
  revenues: yup.array().of(yup.object({
    key: yup.string(),
    path: yup.string()
  })).min(1).required('Documento obrigatório'),
  socialContract: yup.array().of(yup.object({
    key: yup.string(),
    path: yup.string()
  })).min(1).required('Documento obrigatório'),
  companyProofResidence: yup.object({
    key: yup.string(),
    path: yup.string()
  }).required('Obrigatório'),
  partnerProofResidence: yup.object({
    key: yup.string(),
    path: yup.string()
  }).required('Obrigatório'),
  companyDocument: yup.object({
    key: yup.string(),
    path: yup.string()
  }).required('Obrigatório'),
  fgtsCertificate: yup.object({
    key: yup.string(),
    path: yup.string()
  }).required('Obrigatório'),
  partnerDocument: yup.object({
    key: yup.string(),
    path: yup.string()
  }).required('Obrigatório'),
  spouseDocument: yup.object({
    key: yup.string(),
    path: yup.string()
  }).when(
    'maritalStatus', 
    {
      is: yup.string().oneOf(Object.values(ValidMaritalStatus)),
      then: yup.object({
        key: yup.string(),
        path: yup.string()
      }).required('Obrigatório')
    }
  ),
  weddingCertificate: yup.object({
    key: yup.string(),
    path: yup.string()
  }).when(
    'maritalStatus', 
    {
      is: yup.string().oneOf(Object.values(ValidMaritalStatus)),
      then: yup.object({
        key: yup.string(),
        path: yup.string()
      }).required('Obrigatório')
    }
  )
});

export default validationForm;
