import React, { useState } from 'react';
import Header from '../../components/Header';
import api from '../../services/api';

import { Container, Form, Title, Input, Button, Message } from './styles';

const Home = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await api.post('/email', { email });
      setLoading(false);
      return setComplete(true);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Container>
      <Header />
      <Form>
        <Title>ENTRE COM SEU E-MAIL PARA VALIDAR O ACESSO</Title>
        <Input placeholder="E-mail" type="e-mail" value={email} onChange={e => setEmail(e.target.value)} />
        <Button onClick={() => handleSubmit()}>
          { loading ? (
            'ENVIANDO E-MAIL...'
          ) : (
            'VALIDAR'
          ) }
        </Button>

        { complete && <Message>Verifique sua caixa de entrada de e-mail e valide sua identidade</Message> }
      </Form>
    </Container>
  );
}

export default Home;
