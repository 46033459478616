import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
`;

export const Title = styled.h2`
  color: #333;
`;

export const Input = styled.input`
  width: 50%;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  background-color: #d6d6d8;
  color: #666667;
  outline-color: #00adef;
  padding: 10px;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const Button = styled.button`
  width: 50%;
  background-color: #000;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 10px;
  cursor: pointer;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const Message = styled.p`
  margin-top: 10px;
  color: #2d4e23;
  background-color: #d3f3c8;
  padding: 10px;
`;
