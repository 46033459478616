import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Controller, UseFormMethods } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import { FormHelperText, Typography } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import MuiAlert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';
import { Snackbar } from '@material-ui/core';
import InputMask from 'react-input-mask';
import { LoanFormFields } from '..';
import cnpjApi from '../../../services/cnpjApi';
import PartnerList from '../../PartnersList';
import CreatePartner from '../../CreatePartner';
import { IPersonal } from '../../../interfaces';

type Props = {
  methods: UseFormMethods<LoanFormFields>;
}

const Alert = (props: any) => {
  return <MuiAlert elevation={10} variant="filled" {...props} />;
}

export default function FirstStep(props: Props) {
  const { methods } = props;
  const { register, formState: { errors }, control, watch, setValue } = methods;
  const [docMask, setDocMask] = useState('999.999.999-999');
  const [error, setError] = useState<boolean>(false);

  const { document, personalInfo } = watch(['document', 'personalInfo']);

  const handleAddPersonal = (personal: IPersonal, onChange: (personal: IPersonal[]) => void) => {
    if (!personal.document || !personal.name) {
      return setError(true);
    }

    const item = personalInfo.find(currentPersonal => currentPersonal.document === personal.document);
    if (!item) {
      onChange([...personalInfo, personal]);
    }
  }

  const handleDeletePersonal = (current: IPersonal) => {
    setValue('personalInfo', personalInfo.filter(currentPersonal => currentPersonal !== current), { shouldDirty: true })
  }

  const onCnpjComplete = async () => {
    if (document.length === 18 && document[document.length - 1] !== '_') {
      const d = document.replaceAll('.', '');
      const dBarra = d.replaceAll('-', '');
      const documentFormatted = dBarra.replaceAll('/', '');
      const { data } = await cnpjApi.get(documentFormatted);
      setValue('name', data.name);
      setValue('addressCep', data.address.zip);
      setValue('address', data.address.street);
      setValue('addressComplement', data.address.details);
      setValue('addressCity', data.address.city);
      setValue('addressDistrict', data.address.neighborhood);
      setValue('phoneFix', data.phone);
      setValue('phoneCel', data.phoneAlt);
    }
  }

  const onDocChange = (e: any, onChange: any) => {
    if (e.target.value[docMask.length - 1] !== '_') {
      setDocMask('99.999.999/9999-99')
    }
    if (e.target.value[0] === '_') {
      setDocMask('999.999.999-999')
    }
    return onChange(e);
  }

  return (
    <Grid container alignItems="center" spacing={2}>
      <Snackbar open={error} autoHideDuration={10000} onClose={() => setError(false)}>
        <Alert onClose={() => setError(false)} severity="error">
          Preencha todos os dados do representante da empresa.
        </Alert>
      </Snackbar>
      <Grid container alignItems="flex-end" md={6}>
        <Grid item xs={11}>
          <Controller
            name="document"
            control={control}
            render={({ onChange, value }) => (
              <InputMask
                mask={docMask}
                value={value}
                maskPlaceholder={null}
                onBlur={onCnpjComplete}
                onChange={e => onDocChange(e, onChange)}
              >
                { () =>
                  <TextField
                    label="CNPJ/CPF"
                    error={!!errors.document}
                    type="text"
                    fullWidth
                  />
                }
              </InputMask>
            )}
          />
        </Grid>
        <Grid item xs={1}>
          <Tooltip title="Informe o CNPJ ou CPF">
            <HelpOutlineIcon style={{ color: '#6d6d6d', cursor: 'pointer' }} />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-end" md={6}>
        <Grid item xs={11}>
          <TextField
            name="name"
            label="Nome/Razão Social"
            error={!!errors.name}
            inputRef={register()}
            type="text"
            fullWidth
          />
        </Grid>
        <Grid item xs={1}>
          <Tooltip title="Informe seu nome ou razão social">
            <HelpOutlineIcon style={{ color: '#6d6d6d', cursor: 'pointer' }} />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-end" md={3} xs={12}>
        <Grid item xs={11}>
          <Controller
            name="addressCep"
            control={control}
            render={({ onChange, value }) => (
              <InputMask
                mask="99999-999"
                value={value}
                maskPlaceholder={null}
                onChange={onChange}
              >
                { () =>
                  <TextField
                    label="CEP"
                    error={!!errors.addressCep}
                    type="text"
                    fullWidth
                  />
                }
              </InputMask>
            )}
          />
        </Grid>
        <Grid item xs={1}>
          <Tooltip title="Informe o CEP da sua residencia">
            <HelpOutlineIcon style={{ color: '#6d6d6d', cursor: 'pointer' }} />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-end" md={3} xs={12}>
        <Grid item xs={11}>
          <TextField
            name="address"
            label="Logradouro"
            error={!!errors.address}
            inputRef={register()}
            type="text"
            fullWidth
          />
        </Grid>
        <Grid item xs={1}>
          <Tooltip title="Informe o endereço; Ex: Avenida Brasil, 2021">
            <HelpOutlineIcon style={{ color: '#6d6d6d', cursor: 'pointer' }} />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-end" md={3} xs={12}>
        <Grid item xs={11}>
          <TextField
            name="addressComplement"
            label="Complemento"
            error={!!errors.addressComplement}
            inputRef={register()}
            type="text"
            fullWidth
          />
        </Grid>
        <Grid item xs={1}>
          <Tooltip title="Casa, Apto, Zona Rural">
            <HelpOutlineIcon style={{ color: '#6d6d6d', cursor: 'pointer' }} />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-end" md={3} xs={12}>
        <Grid item xs={11}>
          <TextField
            name="addressCity"
            label="Cidade"
            error={!!errors.addressCity}
            inputRef={register()}
            type="text"
            fullWidth
          />
        </Grid>
        <Grid item xs={1}>
          <Tooltip title="Informe cidade e UF">
            <HelpOutlineIcon style={{ color: '#6d6d6d', cursor: 'pointer' }} />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          name="addressDistrict"
          label="Bairro"
          error={!!errors.addressDistrict}
          inputRef={register()}
          type="text"
          fullWidth
        />
      </Grid>
      <Grid container alignItems="flex-end" md={3} xs={12}>
        <Grid item xs={11}>
          <Controller
            name="phoneFix"
            control={control}
            render={({ onChange, value }) => (
              <InputMask
                mask="(99) 9999-9999"
                value={value}
                maskPlaceholder={null}
                onChange={onChange}
              >
                { () =>
                  <TextField
                    label="Telefone Fixo"
                    error={!!errors.phoneFix}
                    type="text"
                    fullWidth
                  />
                }
              </InputMask>
            )}
          />
        </Grid>
        <Grid item xs={1}>
          <Tooltip title="Informe telefone fixo">
            <HelpOutlineIcon style={{ color: '#6d6d6d', cursor: 'pointer' }} />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-end" md={3} xs={12}>
        <Grid item xs={11}>
          <Controller
            name="phoneCel"
            control={control}
            render={({ onChange, value }) => (
              <InputMask
                mask="(99) 99999-9999"
                value={value}
                maskPlaceholder={null}
                onChange={onChange}
              >
                { () =>
                  <TextField
                    label="Telefone Celular"
                    type="text"
                    error={!!errors.phoneCel}
                    fullWidth
                  />
                }
              </InputMask>
            )}
          />
        </Grid>
        <Grid item xs={1}>
          <Tooltip title="Informe telefone celular. Ex: (31) 98626-3063">
            <HelpOutlineIcon style={{ color: '#6d6d6d', cursor: 'pointer' }} />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container justify="center" style={{ margin: '10px 0' }} xs={12}>
        <Typography style={{ fontWeight: 'bold' }}>REPRESENTANTE DA EMPRESA</Typography>
      </Grid>
      {errors.personalInfo && (
        <Grid container justify="center" style={{ margin: '10px 0' }} xs={12}>
          <FormHelperText style={{ color: '#cf4647' }}>Deve ter pelo menos 1 representante.</FormHelperText>
        </Grid>
      )}
      <Controller
        name="personalInfo"
        control={control}
        render={({ onChange }) => (
          <CreatePartner
            onAdd={(data: IPersonal) => handleAddPersonal(data, onChange)}
          />
        )}
      />
      <PartnerList
        items={personalInfo}
        handleDelete={(data: IPersonal) => handleDeletePersonal(data)}
      />
    </Grid>
  )
}
