import React, { useCallback, useEffect, useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { useHistory, useParams } from 'react-router';

import api from '../../services/api';
import { Container, TableWrapper, SendButton, ExitButton, Actions } from './styles';
import CreateLoan from '../../components/CreateLoan';
import Header from '../../components/Header';

type EmailParams = {
  email: string;
}

interface IEmail {
  _id: String;
  email: String;
  cnpj: String;
  name: String;
}

const Alert = (props: any) => {
  return <MuiAlert elevation={10} variant="filled" {...props} />;
}

const LoanList = () => {
  const [loans, setLoans] = useState([]);
  const [client, setClient] = useState<Partial<IEmail> | null>(null);
  const [loan, setLoan] = useState(null);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [success, setSuccess] = useState(false);
  const { email } = useParams<EmailParams>();
  const user = localStorage.getItem('user');
  const history = useHistory();

  const getLoans = useCallback(async () => {
    try {
      const result = await api.get(`/loan/email/${email}`);
  
      setLoans(result.data);
    } catch (err) {
      console.log(err);
      return history.push('/');
    }
  }, []);

  const handleSubmit = useCallback(async (loan: any, activeStep: any) => {
    try {
      if (loan._id) {
        await api.put(`loan/update/${loan._id}`, loan);
        if (activeStep >= 3) {
          setEdit(false);
          setOpen(false);
          setSuccess(true);
        }
      } else {
        const result = await api.post('loan', loan);
        setLoan(result.data);
      }
      getLoans();
    } catch (err) {
      console.log(err)
    }
  }, []);

  const handleCancel = useCallback(async (loan: any) => {
    try {
      console.log(loan)
      if (loan._id) {
        await api.put(`loan/update/${loan._id}`, loan);
        setEdit(false);
        setOpen(false);
      } else {
        await api.post('loan', loan);
        setEdit(false);
        setOpen(false);
      }
      getLoans();
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (!email) {
      history.push('/');
    }
    getLoans();

    (async () => {
      const { data } = await api.get(`email/details/${email}`);

      setClient(data);
    })();
  }, []);

  return (
    <Container>
      <Header />
      <Snackbar open={success} autoHideDuration={10000} onClose={() => setSuccess(false)}>
        <Alert onClose={() => setSuccess(false)} severity="success">
          Suas informações foram enviadas. Breve retornaremos o contato.
        </Alert>
      </Snackbar>
      { edit ? (
        <CreateLoan
          opened={edit}
          loan={loan}
          handleFormSubmit={(data, activeStep) => handleSubmit(data, activeStep)}
          handleCancel={handleCancel}
        />
      ) : null }

      { open ? (
        <CreateLoan
          opened={open}
          loan={loan}
          handleFormSubmit={(data, activeStep) => handleSubmit(data, activeStep)}
          handleCancel={handleCancel}
          />
          ) : null }

      <Typography style={{ marginBottom: '20px' }}>{client?.name || client?.email}</Typography>
      <Actions>
        <SendButton onClick={() => {
          setLoan(null)
          setOpen(true)
        }}>NOVO CADASTRO</SendButton>
        <ExitButton
          style={{ marginLeft: '10px' }}
          onClick={() => user ? history.push('/emails') : history.push('/')}
        >
          { user ? 'VOLTAR' : 'SAIR' }
        </ExitButton>
      </Actions>
      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>ACOMPANHAMENTO</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>STATUS</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>ÚLTIMA INTERAÇÃO</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>AÇÃO</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { loans.map((loan: any) => (
              <TableRow style={{ backgroundColor: loan.status === 'FINALIZADO' ? '#d3f3c8' : 'white' }}>
                <TableCell>{loan._id}</TableCell>
                <TableCell>{loan.status}</TableCell>
                <TableCell>{format(new Date(loan.updatedAt), 'dd/MM/yyyy HH:mm')}</TableCell>
                <TableCell>
                  <SendButton onClick={() => {
                    setLoan(loan)
                    setEdit(true)
                  }}>Editar</SendButton>
                  { user && (
                    <SendButton style={{ marginLeft: '5px' }} onClick={
                      () => history.push(`/details/${loan._id}`)
                    }>Ficha básica</SendButton>
                  ) }
                </TableCell>
              </TableRow>
            )) }
          </TableBody>
        </Table>
      </TableWrapper>
    </Container>
  )
}

export default LoanList;