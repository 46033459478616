import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Controller, UseFormMethods } from 'react-hook-form';
import { LoanFormFields } from '..';
import InputFile from '../../InputFile';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import InputFiles from '../../InputFiles';
import { IFile } from '../../../interfaces';

type Props = {
  methods: UseFormMethods<LoanFormFields>;
  id: string;
}

export default function FourthStep(props: Props) {
  const { methods, id } = props;
  const { watch, formState: { errors }, control } = methods;

  const {
    maritalStatus,
    socialContract,
    revenues,
    companyDocument,
    balanceSheet,
    debtReport,
    simplifiedCertificate,
    fgtsCertificate,
    companyProofResidence,
    partnerDocument,
    partnerProofResidence,
    spouseDocument,
    weddingCertificate,
    incomeTaxReturn
  } = watch([
      'maritalStatus',
      'socialContract',
      'revenues',
      'companyDocument',
      'balanceSheet',
      'debtReport',
      'simplifiedCertificate',
      'fgtsCertificate',
      'companyProofResidence',
      'partnerDocument',
      'partnerProofResidence',
      'spouseDocument',
      'weddingCertificate',
      'incomeTaxReturn'
    ]);

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid container justify="center" style={{ margin: '10px 0' }} md={12}>
        <Controller
          name="socialContract"
          control={control}
          render={({ onChange }) => (
            <InputFiles
              id={id}
              type="socialContract"
              tooltip="Cópia autenticada ou autenticação digital da Junta Comercial"
              currentFile={socialContract}
              placeholder="Contrato Social"
              handleSubmit={(urls: IFile[]) => onChange(urls)}
            />
          )}
        />
        {errors.socialContract && <FormHelperText style={{ color: '#cf4647' }}>Documento obrigatório.</FormHelperText>}
      </Grid>
      <Grid container justify="center" style={{ margin: '10px 0' }} md={12}>
        <Controller
          name="revenues"
          control={control}
          render={({ onChange }) => (
            <InputFiles
              id={id}
              type="revenues"
              tooltip="Assinado e carimbado pelo Contador, informando o número do CRC"
              currentFile={revenues}
              placeholder="Faturamento últimos 12 meses"
              handleSubmit={(urls: IFile[]) => onChange(urls)}
            />
          )}
        />
        {errors.revenues && <FormHelperText style={{ color: '#cf4647' }}>Documento obrigatório.</FormHelperText>}
      </Grid>
      <Grid container justify="center" style={{ margin: '10px 0' }} md={12}>
        <Controller
          name="incomeTaxReturn"
          control={control}
          render={({ onChange }) => (
            <InputFile
              id={id}
              type="incomeTaxReturn"
              currentFile={incomeTaxReturn}
              placeholder="Declaração imposto de renda"
              handleSubmit={(url: IFile) => onChange(url)}
            />
          )}
        />
      </Grid>
      <Grid container justify="center" style={{ margin: '10px 0' }} md={12}>
        <Controller
          name="debtReport"
          control={control}
          render={({ onChange }) => (
            <InputFiles
              id={id}
              type="debtReport"
              currentFile={debtReport}
              placeholder="Relatório de Endividamento"
              handleSubmit={(urls: IFile[]) => onChange(urls)}
            />
          )}
        />
      </Grid>
      <Grid container justify="center" style={{ margin: '10px 0' }} md={12}>
        <Controller
          name="balanceSheet"
          control={control}
          render={({ onChange }) => (
            <InputFiles
              id={id}
              type="balanceSheet"
              tooltip="Assinado e carimbado pelo Administrador e Contador"
              currentFile={balanceSheet}
              placeholder="Balanço Patrimonial"
              handleSubmit={(urls: IFile[]) => onChange(urls)}
            />
          )}
        />
      </Grid>
      <Grid container justify="center" style={{ margin: '10px 0' }} md={12}>
        <Controller
          name="companyDocument"
          control={control}
          render={({ onChange }) => (
            <InputFile
              id={id}
              type="companyDocument"
              currentFile={companyDocument}
              placeholder="Cartão CNPJ"
              handleSubmit={(url: IFile) => onChange(url)}
            />
          )}
        />
        {errors.companyDocument && <FormHelperText style={{ color: '#cf4647' }}>Documento obrigatório.</FormHelperText>}
      </Grid>
      <Grid container justify="center" style={{ margin: '10px 0' }} md={12}>
        <Controller
          name="companyProofResidence"
          control={control}
          render={({ onChange }) => (
            <InputFile
              id={id}
              tooltip="Conta de consumo (água,luz ou telefone) com data dos últimos 90 dias"
              type="companyProofResidence"
              currentFile={companyProofResidence}
              placeholder="Comprovante de endereço"
              handleSubmit={(url: IFile) => onChange(url)}
            />
          )}
        />
        {errors.companyProofResidence && 
          <FormHelperText style={{ color: '#cf4647' }}>Documento obrigatório.</FormHelperText>}
        <a href="https://loan-management.s3.sa-east-1.amazonaws.com/DECLARA%C3%87%C3%83O+DE+RESID%C3%8ANCIA+-+PESSOA+JUR%C3%8DDICA.docx" target="_blank" rel="noopener noreferrer">Download modelo</a>
      </Grid>
      <Grid container justify="center" style={{ margin: '10px 0' }} md={12}>
        <Controller
          name="fgtsCertificate"
          control={control}
          render={({ onChange }) => (
            <InputFile
              id={id}
              type="fgtsCertificate"
              tooltip="Com período válido no momento do cadastro"
              currentFile={fgtsCertificate}
              placeholder="Certidão de FGTS"
              handleSubmit={(url: IFile) => onChange(url)}
            />
          )}
        />
        {errors.fgtsCertificate && <FormHelperText style={{ color: '#cf4647' }}>Documento obrigatório.</FormHelperText>}
      </Grid>
      <Grid container justify="center" style={{ margin: '10px 0' }} md={12}>
        <Controller
          name="simplifiedCertificate"
          control={control}
          render={({ onChange }) => (
            <InputFile
              id={id}
              type="simplifiedCertificate"
              tooltip="Data de emissão dos últimos 30 dias"
              currentFile={simplifiedCertificate}
              placeholder="Certidão Simplificada"
              handleSubmit={(url: IFile) => onChange(url)}
            />
          )}
        />
      </Grid>
      <Grid container justify="center" style={{ margin: '10px 0' }} xs={12}>
        <Typography style={{ fontWeight: 'bold' }}>DOCUMENTOS DOS SÓCIOS/REPRESENTANTES</Typography>
      </Grid>
      <Grid container justify="center" style={{ margin: '10px 0' }} md={12}>
        <Controller
          name="partnerDocument"
          control={control}
          render={({ onChange }) => (
            <InputFile
              id={id}
              type="partnerDocument"
              currentFile={partnerDocument}
              placeholder="Documento de identidade"
              handleSubmit={(url: IFile) => onChange(url)}
            />
          )}
        />
        {errors.partnerDocument && <FormHelperText style={{ color: '#cf4647' }}>Documento obrigatório.</FormHelperText>}
      </Grid>
      <Grid container justify="center" style={{ margin: '10px 0' }} md={12}>
        <Controller
          name="partnerProofResidence"
          control={control}
          render={({ onChange }) => (
            <InputFile
              id={id}
              type="partnerProofResidence"
              currentFile={partnerProofResidence}
              placeholder="Comprovante de endereço"
              handleSubmit={(url: IFile) => onChange(url)}
            />
          )}
        />
        {errors.partnerProofResidence &&
          <FormHelperText style={{ color: '#cf4647' }}>Documento obrigatório.</FormHelperText>}
      </Grid>
      <Grid item md={3} xs={12}>
        <Controller
          name="maritalStatus"
          control={control}
          render={({ onChange }) => (
            <FormControl fullWidth>
              <InputLabel>
                Estado Civil
              </InputLabel>
              <Select
                value={watch('maritalStatus')}
                onChange={onChange}
              >
                <MenuItem value="CASADO(A) COMUNHÃO PARCIAL">CASADO(A) COMUNHÃO PARCIAL</MenuItem>
                <MenuItem value="CASADO(A) COMUNHÃO TOTAL">CASADO(A) COMUNHÃO TOTAL</MenuItem>
                <MenuItem value="CASADO(A) SEPARAÇÃO TOTAL">CASADO(A) SEPARAÇÃO TOTAL</MenuItem>
                <MenuItem value="DIVORCIADO(A)">DIVORCIADO(A)</MenuItem>
                <MenuItem value="SOLTEIRO(A)">SOLTEIRO(A)</MenuItem>
              </Select>
            </FormControl>
          )}
        />
      </Grid>
      { maritalStatus === 'CASADO(A) COMUNHÃO PARCIAL'
        || maritalStatus === 'CASADO(A) COMUNHÃO TOTAL'
        || maritalStatus === 'CASADO(A) SEPARAÇÃO TOTAL' ? (
        <Grid container style={{ margin: '10px 0' }} xs={12}>
          <Grid container justify="center" style={{ margin: '10px 0' }} md={12}>
            <Controller
              name="spouseDocument"
              control={control}
              render={({ onChange }) => (
                <InputFile
                  id={id}
                  type="spouseDocument"
                  currentFile={spouseDocument}
                  placeholder="Documento Conjuge"
                  handleSubmit={(url: IFile) => onChange(url)}
                />
              )}
            />
            {errors.spouseDocument &&
              <FormHelperText style={{ color: '#cf4647' }}>Documento obrigatório.</FormHelperText>}
          </Grid>
          <Grid container justify="center" style={{ margin: '10px 0' }} md={12}>
            <Controller
              name="weddingCertificate"
              control={control}
              render={({ onChange }) => (
                <InputFile
                  id={id}
                  type="weddingCertificate"
                  currentFile={weddingCertificate}
                  placeholder="Certidão de casamento"
                  handleSubmit={(url: IFile) => onChange(url)}
                />
              )}
            />
            {errors.weddingCertificate &&
              <FormHelperText style={{ color: '#cf4647' }}>Documento obrigatório.</FormHelperText>}
          </Grid>
        </Grid>
      ) : null }
    </Grid>
  )
}
