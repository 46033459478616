import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Controller, UseFormMethods } from 'react-hook-form';
import { FormHelperText, Snackbar, Typography } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import DynamicList from '../../DynamicList';
import { IThird } from '../../../interfaces';
import { LoanFormFields } from '..';
import CreateThird from '../../CreateThird';

type Props = {
  methods: UseFormMethods<LoanFormFields>;
}

const Alert = (props: any) => {
  return <MuiAlert elevation={10} variant="filled" {...props} />;
}

export default function ThirdStep(props: Props) {
  const { methods } = props;
  const { watch, formState: { errors }, setValue, control } = methods;
  const [error, setError] = useState<boolean>(false);

  const {clients, providers} = watch(['clients', 'providers']);

  const handleAddClient = (client: IThird, onChange: (client: IThird[]) => void) => {
    if (!client.document || !client.name || !client.value) {
      return setError(true);
    }

    const item = clients.find(currentClient => currentClient.document === client.document);
    if (!item && clients.length < 5) {
      onChange([...clients, client]);
    }
  }

  const handleAddProvider = (provider: IThird, onChange: (provider: IThird[]) => void) => {
    if (!provider.document || !provider.name || !provider.value) {
      return setError(true);
    }

    const item = providers.find(currentProvider => currentProvider.document === provider.document);
    if (!item && providers.length < 5) {
      onChange([...providers, provider]);
    }
  }

  const handleDeleteClient = (current: IThird) => {
    setValue('clients', clients.filter(client => client !== current), { shouldDirty: true })
  }

  const handleDeleteProviders = (current: IThird) => {
    setValue('providers', providers.filter(provider => provider !== current), { shouldDirty: true })
  }

  return (
    <Grid container alignItems="center" spacing={2}>
      <Snackbar open={error} autoHideDuration={10000} onClose={() => setError(false)}>
        <Alert onClose={() => setError(false)} severity="error">
          Preencha todos os dados corretamente.
        </Alert>
      </Snackbar>
      <Grid container justify="center" style={{ margin: '10px 0' }} xs={12}>
        <Typography style={{ fontWeight: 'bold', marginTop: '10px' }}>PRINCIPAIS CLIENTES</Typography>
      </Grid>
      {errors.clients && (
        <Grid container justify="center" style={{ margin: '10px 0' }} xs={12}>
          <FormHelperText style={{ color: '#cf4647' }}>Deve ter pelo menos 1 cliente.</FormHelperText>
        </Grid>
      )}
      <Controller
          name="clients"
          control={control}
          render={({ onChange }) => (
            <CreateThird
              onAdd={(data: IThird) => handleAddClient(data, onChange)}
              type="Clientes"
              tooltip="Informe uma média de faturamento com esse cliente"
            />
        )}
      />
      <Grid item xs={12}>
        <DynamicList items={clients} handleDelete={client => handleDeleteClient(client)} />
      </Grid>
      <Grid container justify="center" style={{ margin: '10px 0' }} xs={12}>
        <Typography style={{ fontWeight: 'bold' }}>PRINCIPAIS FORNECEDORES</Typography>
      </Grid>
      {errors.clients && (
        <Grid container justify="center" style={{ margin: '10px 0' }} xs={12}>
          <FormHelperText style={{ color: '#cf4647' }}>Deve ter pelo menos 1 fornecedor.</FormHelperText>
        </Grid>
      )}
      <Controller
        name="providers"
        control={control}
        render={({ onChange }) => (
          <CreateThird
            onAdd={(data: IThird) => handleAddProvider(data, onChange)}
            type="Fornecedor"
            tooltip="Informe uma média de compra neste fornecedor"
          />
        )}
      />
      <Grid item xs={12}>
        <DynamicList items={providers} handleDelete={provider => handleDeleteProviders(provider)} />
      </Grid>
    </Grid>
  )
}
