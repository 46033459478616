import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import { IThird } from '../../interfaces';

import { Button } from './styles';

type Props = {
  items: IThird[];
  handleDelete: (third: IThird) => void;
}

const DynamicList = (props: Props) => {
  const { items, handleDelete } = props;

  return (
    <div style={{ maxHeight: 200, overflow: 'auto', width: '100%' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>CPF/CNPJ</TableCell>
            <TableCell>Razão Social</TableCell>
            <TableCell>Valor Compra Mês</TableCell>
            <TableCell>Ação</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { items.map((third, i) => (
            <TableRow style={{ background: '#d3f3c8' }} key={i}>
              <TableCell>{third.document}</TableCell>
              <TableCell>{third.name}</TableCell>
              <TableCell>R$ {third.value}</TableCell>
              <TableCell>
                <Button onClick={() => handleDelete(third)}>Excluir</Button>
              </TableCell>
            </TableRow>
          )) }
        </TableBody>
      </Table>
    </div>
  );
}

export default DynamicList;
