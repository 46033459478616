import React from 'react';
import { useForm } from 'react-hook-form';
import Dialog from '@material-ui/core/Dialog';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router';
import Box from '@material-ui/core/Box';
import FormStepper from '../FormStepper';
import FirstStep from './steps/FirstStep';
import { IBank, IFile, IPersonal, IThird } from '../../interfaces';
import SecondStep from './steps/SecondStep';
import ThirdStep from './steps/ThirdStep';
import FourthStep from './steps/FourthStep';
import validationForm from './formValidator';
import { MaritalStatus } from '../../interfaces/enum';

export type LoanFormFields = {
  email: {
    _id: string;
  };
  status: string;
  document: string;
  name: string;
  addressCep: string;
  address: string;
  addressComplement: string;
  addressCity: string;
  addressDistrict: string;
  phoneFix: string;
  phoneCel: string;
  initialDate: Date;
  primaryActivity: string;
  employeersQtd: number;
  website: string;

  personalInfo: IPersonal[];
  bankInfo: IBank;
  clients: IThird[];
  providers: IThird[];
  
  socialContract: IFile[];
  revenues: IFile[];
  companyDocument: IFile;
  balanceSheet: IFile[];
  debtReport: IFile[];
  simplifiedCertificate: IFile;
  fgtsCertificate: IFile;
  companyProofResidence: IFile;

  partnerDocument: IFile;
  partnerProofResidence: IFile;
  maritalStatus: MaritalStatus;
  spouseDocument: IFile;
  weddingCertificate: IFile;
  incomeTaxReturn: IFile;
}

type Props = {
  opened: boolean;
  loan?: any;
  handleFormSubmit: (data: {}, activeStep: any) => void;
  handleCancel: (data: {}) => void;
};

type EmailParams = {
  email: string;
}

export default function CreateLoan(props: Props) {
  const { opened, loan, handleFormSubmit, handleCancel } = props;
  const { email } = useParams<EmailParams>();

  const methods = useForm<LoanFormFields>({
    resolver: yupResolver(validationForm),
    shouldUnregister: false,
    mode: 'all',
    defaultValues: {
      email: {
        _id: email,
      },
      document: loan?.document ?? '',
      name: loan?.name ?? '',
      addressCep: loan?.addressCep ?? '',
      address: loan?.address ?? '',
      addressComplement: loan?.addressComplement ?? '',
      addressCity: loan?.addressCity ?? '',
      addressDistrict: loan?.addressDistrict ?? '',
      phoneFix: loan?.phoneFix ?? '',
      phoneCel: loan?.phoneCel ?? '',
      initialDate: loan?.initialDate ?? new Date(),
      primaryActivity: loan?.primaryActivity ?? '',
      employeersQtd: loan?.employeersQtd ?? 0,
      website: loan?.website ?? '',
      personalInfo: loan?.personalInfo ?? [],
      bankInfo: {
        bank: loan?.bankInfo?.bank ?? '',
        agency: loan?.bankInfo?.agency ?? '',
        account: loan?.bankInfo?.account ?? '',
      },
      clients: loan?.clients ?? [],
      providers: loan?.providers ?? [],
      socialContract: loan?.socialContract ?? [],
      revenues: loan?.revenues ?? [],
      companyDocument: loan?.companyDocument ?? null,
      balanceSheet: loan?.balanceSheet ?? [],
      debtReport: loan?.debtReport ?? [],
      simplifiedCertificate: loan?.simplifiedCertificate ?? null,
      fgtsCertificate: loan?.fgtsCertificate ?? null,
      companyProofResidence: loan?.companyProofResidence ?? null,
      partnerDocument: loan?.partnerDocument ?? null,
      partnerProofResidence: loan?.partnerProofResidence ?? null,
      maritalStatus: loan?.maritalStatus ?? '',
      spouseDocument: loan?.spouseDocument ?? null,
      weddingCertificate: loan?.weddingCertificate ?? null,
      incomeTaxReturn: loan?.incomeTaxReturn ?? null,
    },
  });

  const { reset, getValues, trigger } = methods;

  const handleNext = async (activeStep: number) => {
    let status = steps[activeStep].label
    if (activeStep >= 3) {
      status = 'FINALIZADO';
    }
    if (loan?._id) {
      handleFormSubmit(
        {
          _id: loan._id,
          ...getValues(),
          status,
        },
        activeStep
      );
    } else {
      handleFormSubmit(
        {
          ...getValues(),
          status,
        },
        activeStep
      );
    }
  }

  const handleFormCancel = (activeStep: number) => {
    const status = steps[activeStep].label;
    if (loan?._id) {
      handleCancel({
        _id: loan._id,
        ...getValues(),
        status,
      });
      reset(loan)
    } else {
      handleCancel({
        ...getValues(),
        status,
      });
      reset();
    }
  }

  const steps = [
    {
      label: 'DADOS CADASTRAIS DA EMPRESA',
      component: <FirstStep methods={methods} />,
    },
    {
      label: 'INFORMAÇÕES COMPLEMENTARES',
      component: <SecondStep methods={methods} />,
    },
    {
      label: 'CLIENTES E FORNECEDORES',
      component: <ThirdStep methods={methods} />,
    },
    {
      label: 'DOCUMENTAÇÃO',
      component: <FourthStep id={loan?._id} methods={methods} />,
    }
  ]

  return (
    <Dialog open={opened} fullWidth maxWidth="lg">
      <div style={{ padding: 20, overflowX: "auto" }}>
        <Box padding="30px" overflow="hidden">
          <FormStepper
            steps={steps}
            triggerValidation={trigger}
            onCancel={handleFormCancel}
            onNext={handleNext}
          />
        </Box>
      </div>
    </Dialog>
  );
}
