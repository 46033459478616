import { Router } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import CssBaseline from '@material-ui/core/CssBaseline';
import DateFnsUtils from '@date-io/date-fns';
import locale from 'date-fns/locale/pt-BR';
import { createBrowserHistory } from 'history';
import Pages from './pages';
import { ThemeProvider } from '@material-ui/core';
import { whiteTheme } from './assets/theme';

const history = createBrowserHistory();

function App() {
  return (
    <Router history={history}>
      <ThemeProvider theme={whiteTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
          <CssBaseline />
          <Pages />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
